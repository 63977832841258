import PageNav from "../comps/PageNav"

export default function TitlePageSection({pageData}) {
  const { data, links } = pageData
  const { title } = data
  return (
    <div>
      <PageNav links={links}/>
      <div className="container d-flex flex-column d-flex justify-content-around min-vh-75">
        <h1 className="title-book text-center">{title}</h1>
      </div>
    </div>
  )
}