import '../css/Cover.css'
import CoverImage from '../images/cover.jpg'
import { useOutletContext , Link } from 'react-router-dom'
import AppNav from '../comps/AppNav/AppNav'

function Cover() {
  const [ context ] = useOutletContext()
  const { location } = context
  const continueButton = location.current ? <ContinueButton location={location.current} /> : ''

  return (
    <div className="d-flex align-items-center">
      <div className='position-relative'>
        <img className='fade-in book-img' src={CoverImage} alt="book cover"/>
        <div className='button-fade-in position-absolute top-50 d-flex w-100 justify-content-around'>
          <OpenButton/>
          {continueButton}
        </div>
        <div className='button-fade-in position-absolute top-0 w-100'>
          <AppNav/>
        </div>
      </div>
    </div>
  )
}

function OpenButton() {
  return (
    <button type="button" className="btn btn-outline-dark glow">
      <Link to='/book/title-page' className='pg-link text-uppercase text-white'>
        Open   
        <i className="fa-solid fa-book-open p-2"></i>
      </Link>
    </button>
  )
}

function ContinueButton({ location }) {
  return (
    <button type="button" className="btn btn-outline-dark glow">
      <Link to='/book/title-page' className='pg-link text-uppercase text-white'>
        Continue   
        <i className="fa-solid fa-book-open p-2"></i>
      </Link>
    </button>
  )
}

export default Cover