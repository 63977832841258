import bookData from '../data/bookData'

export default function getBook() {
  const {info, copyrightInfo, editionInfo, editorialNote, foreword, frontPage, book, notes, shortBio, speech} = bookData

  let [introduction, q, conclusion] = book
  const introTitlePage = { title: 'Introduction'}
  const qTitlePage = { title: "The Qur-an"}
  const conclusionTitlePage = { title: 'Conclusion'}

  const getUrlName = str => str.replace(/\W+/g, '-').toLowerCase()
  const getLink = chapter => {
    return `${getUrlName(chapter.title)}`
  }

  const getQLink = chapter => {
    const { data } = chapter
    const firstChap = data[0].index
    const lastChap = data[data.length-1].index
    return `C${firstChap}-C${lastChap}`
  }

  const start = [
    processData(getLink(info), 'title-page', info, [null, getLink(copyrightInfo)]),
    processData(getLink(copyrightInfo), 'copyright-info', copyrightInfo, [getLink(info), getLink(editionInfo)]),
    processData(getLink(editionInfo), 'ed-info', editionInfo, [getLink(copyrightInfo), getLink(editorialNote)]),
    processData(getLink(editorialNote), 'ed-note', editorialNote, [getLink(editionInfo), getLink(foreword)]),
    processData(getLink(foreword), 'foreword', foreword, [getLink(editorialNote), getLink(frontPage)]),
    processData(getLink(frontPage), 'front-page', frontPage, [getLink(foreword), getLink(introTitlePage)])
  ]

  
  const introTitlePageObj = processData(getLink(introTitlePage), 'intro-title-page', introTitlePage, [getLink(frontPage), getQLink(introduction.data[0])])
  const introduction2 = introduction.data.map((c, i) => {
    const firstLink = (i === 0) ? getLink(introTitlePage) : getQLink(introduction.data[i-1])
    const lastLink = introduction.data[i+1] ? getQLink(introduction.data[i+1]) : getLink(qTitlePage)
    return processData(getQLink(c), 'q', c, [firstLink, lastLink])
  })
  introduction2.unshift(introTitlePageObj)


  const qTitlePageObj = processData(getLink(qTitlePage), 'q-title-page', qTitlePage, [getQLink(introduction.data[introduction.data.length -1]), getQLink(q.data[0])])
  const q2 = q.data.map((c, i) => {
    const firstLink = (i === 0) ? getLink(qTitlePage) : getQLink(q.data[i-1])
    const lastLink = q.data[i+1] ? getQLink(q.data[i+1]) : getQLink(conclusion.data[0])
    return processData(getQLink(c),'q', c, [firstLink, lastLink])
  })
  q2.unshift(qTitlePageObj)

  const conclusionTitlePageObj = processData(getLink(conclusionTitlePage), 'conclusion-title-page', conclusionTitlePage, [getQLink(q.data[q.data.length -1]), getQLink(conclusion.data[0])])
  const conclusion2 = conclusion.data.map((c, i) => {
    const firstLink = (i === 0) ? getQLink(q.data[q.data.length -1]) : getQLink(conclusion.data[i-1])
    const lastLink = conclusion.data[i+1] ? getQLink(conclusion.data[i+1]) : getLink(notes)
    return processData(getQLink(c), 'q', c, [firstLink, lastLink])
  })
  conclusion2.unshift(conclusionTitlePageObj)

  const middle = [].concat(introduction2, q2, conclusion2)
  const end = [
    processData(getLink(notes),'notes', notes, [getQLink(conclusion.data[conclusion.data.length -1]), getLink(shortBio)]),
    processData(getLink(shortBio),'bio', shortBio, [getLink(notes), getLink(speech)]),
    processData(getLink(speech), 'speech',speech, [getLink(shortBio), null])
  ]

  return [].concat(start, middle, end).map((e, i) => {
    e.index = i
    return e
  })
}

function processData(linkName, typeName, data, links) {
  return {
    linkName,
    typeName,
    data,
    links
  }
}