import PageNav from "../comps/PageNav"
import addLineBreaks from "../funcs/addLineBreaks"

export default function EditorsNote({pageData}) {
  const { data, links } = pageData
  const { title, text, details  } = data
  const { names, location } = details
  const edNames = addLineBreaks(names)
  const lines = text.map( (t, i)=> {
    const p = { __html: t }
    return <p key={i} dangerouslySetInnerHTML={p}/>
  })
  return (
    <div>
      <PageNav links={links}/>
      <div className="container min-vh-75">
        <h2 className="text-center my-5">{title}</h2>
        {lines}
        <p className="text-end">{edNames}</p>
        <p className="text-end">{location}</p>
      </div>
    </div>
  )
}