import '../../css/AppNav.css'
import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Info from './Info';
// import Settings from './Settings';
import Contents from './Contents';

export default function AppNav () {

  const [showInfo, setShowInfo] = useState(false)
  const handleCloseInfo = () => setShowInfo(false);
  const handleShowInfo = () => setShowInfo(true);

  const [showContents, setShowContents] = useState(false);
  const handleCloseContents = () => setShowContents(false);
  const handleShowContents = () => setShowContents(true);

  const navigate = useNavigate();

  return (
    <Navbar expand="lg" >
      <Container>
        <div className='d-flex'>
          <Button onClick={() => navigate('/')} variant="outline-light" className='nav-button m-1' size="sm"><i className="fa-solid fa-book"></i></Button>
          <Button onClick={handleShowInfo} variant="outline-light" className='nav-button m-1' size="sm"><i className="fa-solid fa-envelope"></i></Button>
          
        </div>
        <div className='d-flex'>
          <Button onClick={handleShowContents} variant="outline-light" className='nav-button m-1' size="sm"><i className="fa-solid fa-list"></i></Button>
        </div>
      </Container>
      <Info show={showInfo} handleClose={handleCloseInfo}/>
      <Contents show={showContents} handleClose={handleCloseContents}/>
    </Navbar>
  )
}