import '../../css/Contents.css'
import '../../css/OffCanvas.css'
import { useNavigate } from "react-router-dom";
import Offcanvas from 'react-bootstrap/Offcanvas';
import bookData from '../../data/bookData';

function Contents({ show, handleClose }) {

  return (
    <Offcanvas placement='end' show={show} onHide={handleClose} backdrop={false}>
      <Offcanvas.Header className='oc-header' closeButton>
        <Offcanvas.Title>Contents</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body className='oc-body'>
        <ContentsBody handleClose={handleClose}/>
      </Offcanvas.Body>
    </Offcanvas>
  )
}

function ContentsBody({handleClose}) {
  return (
    <div className="contents" id="main" role="main" aria-label="Content">
      <ul id="contents-list" className="post-list">
        {getContents(bookData, handleClose)}
      </ul>
    </div>
  )
}

function getContents(bookData, handleClose) {
  const {info, copyrightInfo, editionInfo, editorialNote, foreword, frontPage, book, notes, shortBio, speech} = bookData
  
  const contentsListBefore = [info, copyrightInfo, editionInfo, editorialNote, foreword, frontPage]
  const contentsListBeforeHtml = contentsListBefore.map((s, i) => <ContentLine key={'clb' + i} section={s} handleClose={handleClose}/>)

  const [introduction, q, conclusion] = book
  const contentsListBook = [introduction, q, conclusion]
  const contentsListBookHtml = contentsListBook.map((s, i) => <BookContentLine key={'qur' + i} section={s} handleClose={handleClose}/>)
  
  const contentsListAfter = [notes, shortBio, speech]
  const contentsListAfterHtml = contentsListAfter.map((s, i) => <ContentLine key={'cla' + i} section={s} handleClose={handleClose}/>)
  return [].concat(contentsListBeforeHtml, contentsListBookHtml,  contentsListAfterHtml)
}

function ContentLine({section, handleClose}) {
  const { title } = section
  const navigate = useNavigate();
  const handleClick = () => {
    navigate(`/book/${getUrlName(title)}`)
    handleClose()
  };

  return (
  <li>
    <span onClick={handleClick}
      className="post-link clickable">
      <div className="post-link__heading">
        <h1 className="post-link__title">
          <u>{title}</u>
        </h1>
      </div>
    </span>
  </li>)
}

function BookContentLine({section, handleClose}) {
  let { title, data } = section
  title =  title.trim()
  const navigate = useNavigate();
  const handleClick = () => {
    navigate(`/book/${getUrlName(title)}`)
    handleClose()
  };

  return (
  <li>
    <span onClick={handleClick}
      className="post-link clickable">
      <div className="post-link__heading">
        <h1 className="post-link__title">
          <u>{title}</u>
        </h1>
      </div>
    </span>
    
    <ul className="post-list">
      {data.map((c, i) => <ChapterLine key={i} chapter={c} handleClose={handleClose}/>)}
    </ul>
  </li>)
}

function ChapterLine({chapter, handleClose}) {
  const { title, data } = chapter
  const firstChap = data[0].index
  const lastChap = data[data.length-1].index

  const navigate = useNavigate();
  const handleClick = () => {
    navigate(`/book/C${firstChap}-C${lastChap}`)
    handleClose()
  };

  return (
  <li>
    <span onClick={handleClick}
      className="post-link clickable">
      <div className="post-link__heading">
        <h1 className="post-link__title">
          {title}
        </h1>
      </div>
      <span className="post-date">
        C.{firstChap} - C.{lastChap}
      </span>
    </span>
  </li>)
}

function getUrlName(str) {
  return str.replace(/\W+/g, '-').toLowerCase()
} 



export default Contents