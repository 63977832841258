import AppNav from '../comps/AppNav/AppNav';
import { Outlet, useOutletContext } from 'react-router-dom'
import '../css/Book.css'

function Book({ contents}) {
  const [ context ] = useOutletContext();
  const { maxWidth} = context
  const maxWidthPx = `${maxWidth}px`
  const style = { width: maxWidthPx}

  return (
    <div id="book" tabIndex="0" className="book" style={style} >
      <AppNav/>
      <Outlet context={[context]}/>
    </div>
  )
}

export default Book