import { Link } from 'react-router-dom'
import '../css/PageNav.css'

export default function PageNav({links}) {
  const handleClick = () => scrollToTop()
  const [prevPage, nextPage] = links
  const prevPageHref = `../${prevPage}`
  const nextPageHref = `../${nextPage}`
  const emptyLink = ''
  const prevHtml = prevPage ? <Link to={prevPageHref} relative="path" className='pg-link text-uppercase'>Previous</Link> : emptyLink
  const nextHtml = nextPage ? <Link to={nextPageHref} relative="path" className='pg-link text-uppercase'>Next</Link> : emptyLink
  return (
    <div className="pg-nav d-flex justify-content-between">
      <span className='pg-nav-link text-center' onClick={handleClick}>{prevHtml}</span>
      <span className='pg-nav-link text-center'>☪</span>
      <span className='pg-nav-link text-center' onClick={handleClick}>{nextHtml}</span>
    </div>
  )
}

function scrollToTop() {
  window.scroll({
    top: 0, 
    left: 0, 
    behavior: 'smooth'
  })
  // console.log('scroll')
}