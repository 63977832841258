import React, { useState } from 'react';
import { useOutletContext } from 'react-router-dom'
import PageNav from "../comps/PageNav"
import '../css/Chapter.css'
import Note from '../comps/Note';


export default function Chapter({pageData}) {
  const [ context ] = useOutletContext();
  const { notes } = context.notes
  const [note, setNote] = useState(null);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const { data, links } = pageData

  const noteDisplay = note ? <Note show={show} handleClose={handleClose} noteId={note} notes={notes}/> : ""

  const paragraphs = data.data.map((d, i) => <Paragraph key={i} d={d} setNote={setNote} setShow={handleShow}/>)
  return (
    <div>
      <PageNav links={links}/>
      <div className="container min-vh-75">
        <h2 className="text-center my-5">{data.title}</h2>
        {paragraphs}
      </div>
      <PageNav links={links}/>
      {noteDisplay}
    </div>
  )
}

function Paragraph({ d, setNote, setShow }) {
  let {index, summary, q, text} = d

  const isNewSummary = q && summary && !summary.startsWith('@')
  summary = isNewSummary ? (<p className="summary">{summary}</p>) : ''
  q = q ? <p className="verse"><small>{q.sName}<br/>({q.s}:{q.v[0]}-{q.v[1]})</small></p> : ''
  
  text = text.map((t,i) => {
    const p = getLine(t, setNote, setShow)
    return <p key={i} className="text-line" >{p}</p>
  })

  return (
    <div id={"C"+ index } className="paragraph m-2">
      <h3 className="text-center">{index}</h3>
      {q}
      {summary}
      <hr className="hr"/>
      {text}
    </div>
  )
}

function getLine(line, setNote, setShow) {
  line = line.trim()
  if(line.includes('^')) {
    const handleClick = e => {
      setNote(e.target.id)
      setShow()
    }
    line = line
      .split('^')
      .map((e, i) => {
        if(isNaN(parseInt(e))) return <span key={i}>{e}</span>
        return <sup key={i} className="clickable" id={'N'+ e} onClick={handleClick}>{e}</sup>
      })
    /*
    const pattern = /\^(\d+)/gm
    const match = pattern.exec(line)[1]
    line = line.replace(pattern, <sup>{match}</sup>)
    */
  }
  if(line === '***') return <span className='hr-span'/>
  return line
}
