import PageNav from "../comps/PageNav"
import '../css/TitlePage.css'

export default function TitlePage({pageData}) {
  const { data, links } = pageData
  const { bookTitle, subtitle, author } = data
  const { name } = author
  return (
    <div>
      <PageNav links={links}/>
      <div className="container d-flex flex-column d-flex justify-content-around min-vh-75">
        <h1 className="title-book text-center">{bookTitle}</h1>
        <p className="subtitle-book text-center fst-italic">{subtitle}</p>
        <p className="author-book text-center text-uppercase">{name}</p>
      </div>
    </div>
  )
}