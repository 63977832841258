import PageNav from "../comps/PageNav"

export default function PublishersNote({pageData}) {
  const { data, links } = pageData
  const { title, text  } = data
  const lines = text.map( (t, i)=> {
    const p = { __html: t }
    return <p key={i} dangerouslySetInnerHTML={p}/>
  })
  return (
    <div>
      <PageNav links={links}/>
      <div className="container min-vh-75">
        <h2 className="text-center my-5">{title}</h2>
        {lines}
      </div>
    </div>
  )
}