import PageNav from "../comps/PageNav"

export default function Notes({pageData}) {
  const { data, links } = pageData
  const { title, notes } = data

  const text = notes.map( (n, i) => {
    const num = n.id.slice(1)
    return <p key={i} className='note'><b>{num}</b>. {n.text}</p>
  })
 
  return (
    <div>
      <PageNav links={links}/>
      <div className="container min-vh-75">
        <h2 className="text-center my-5">{title}</h2>
        { text }
      </div>
    </div>
  )
}