import './css/App.css'
import React, { useState, useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import bookData from './data/bookData'


function App() {
  const [ lastLocation, setLastLocation] = useState(getLastLocation())
  const [windowSize, setWindowSize] = useState(getWindowSize())
  const [maxWidth, setMaxWidth] = useState(getMaxWidth(windowSize))

  // user screen dimensions
  useEffect(() => {
    const newWindowSize = getWindowSize()
    const handleWindowResize = () => setWindowSize(newWindowSize)
    window.addEventListener('resize', handleWindowResize); // the effect
    setMaxWidth(getMaxWidth(newWindowSize))
    return () => window.removeEventListener('resize', handleWindowResize); // Specify how to clean up after this effect
  }, [windowSize.innerWidth]);

  useEffect(() => {
    localStorage.setItem("lastLocation", JSON.stringify(lastLocation));
  }, [lastLocation])

  const context = {
    maxWidth,
    location: {
      current: lastLocation,
      set: setLastLocation
    },
    notes : bookData.notes
  }

  return (
    <main className="app">
      <Outlet context={[context]}/>
    </main>
  );
}

function getWindowSize() {
  const {innerWidth, innerHeight} = window;
  return {innerWidth, innerHeight};
}

function getMaxWidth(windowSize) {
  const bookCoverDimensions = [1410, 2250]
  const {innerWidth, innerHeight} = windowSize;
  const [bookCoverWidth, bookCoverHeight ] = bookCoverDimensions
  let maxWidth =  innerHeight * (bookCoverWidth/ bookCoverHeight)
  if(maxWidth > innerWidth) maxWidth = innerWidth
  return maxWidth
}

function getLastLocation() {
  const saved = localStorage.getItem("lastLocation");
  const initialValue = JSON.parse(saved)
  return initialValue || null;
}

export default App;
