export default function PageInfoItem({title, body}) {
  return (
    <div className='page-info-item m-2'>
      <div>
        <div>
          <div className='d-flex h-100 align-items-center justify-content-center'>
            <h2 className='text-center text-decoration-underline page-info-item-title p-2'>{title}</h2>
          </div>
        </div>
        <div className="page-info-item-body p-2">
          {body}
        </div>
      </div>
    </div>
  )
}