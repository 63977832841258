import PageNav from "../comps/PageNav"
import addLineBreaks from "../funcs/addLineBreaks"

export default function AuthorsForeword({pageData}) {
  const { data, links } = pageData
  const { title, text, details  } = data
  const { name, location, date } = details
  const lines = text.map( (t, i)=> {
    if(typeof t === 'string') return <p key={i}>{t}</p>
    return <p key={i} className="p-2">{addLineBreaks(t.text)}</p>
  })
  return (
    <div>
      <PageNav links={links}/>
      <div className="container min-vh-75">
        <h2 className="text-center my-5">{title}</h2>
        {lines}
        <p className="text-end">{name}</p>
        <p className="text-end">{location}</p>
        <p className="text-end">{date}</p>
      </div>
    </div>
  )
}