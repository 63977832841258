import Offcanvas from 'react-bootstrap/Offcanvas';

export default function Note({show, handleClose, noteId, notes}) {
  
  const note = noteId && notes.find(n => n.id === noteId)
  const text = note && note.text 

  return (
    <Offcanvas show={show} onHide={handleClose} placement='bottom'>
      <Offcanvas.Header className='oc-header' closeButton>
        <Offcanvas.Title>Note {noteId && noteId.slice(1)}</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <p>{text}</p>
      </Offcanvas.Body>
    </Offcanvas>
  )
}