import PageNav from "../comps/PageNav"
import AuthorImage from "../images/npg.jpg"

export default function Bio({pageData}) {
  const { data, links } = pageData
  const { title, text, source } = data
 
  return (
    <div>
      <PageNav links={links}/>
      <div className="container min-vh-75">
        <h2 className="text-center my-5">{title}</h2>
        <ImageHolder imgSrc={AuthorImage}/>
        <p>{text}</p>
        <p className="text-end">from {source}</p>
      </div>
    </div>
  )
}

function ImageHolder({ imgSrc }) {
  return (
    <div className="d-flex justify-content-center">
      <div className="w-50 p-3"><img src={imgSrc} className="img-fluid" alt="author-portrait"/></div>
    </div>
  )
}