import getBook from "./getBook"
import { Route } from 'react-router-dom'
import TitlePage from "../pages/TitlePage"
import CopyrightInfo from "../pages/CopyrightInfo"
import PublishersNote from "../pages/PubNote"
import EditorsNote from "../pages/EdNote"
import AuthorsForeword from "../pages/AuthorsForeword"
import Epigraph from "../pages/Epigraph"
import TitlePageSection from "../pages/TitlePageSection"
import Chapter from "../pages/Chapter"
import Notes from "../pages/Notes"
import Bio from "../pages/Bio"
import Speech from "../pages/Speech"

function getBookRoutes() {
  const book = getBook()
  return book.map((pg, i) => {
    const { linkName, typeName } = pg
    if(typeName === 'title-page') return <Route key={i} path={linkName} element={<TitlePage pageData={pg}/>}/>
    if(typeName === 'copyright-info') return <Route key={i} path={linkName} element={<CopyrightInfo pageData={pg}/>} />
    if(typeName === 'ed-info') return <Route key={i} path={linkName} element={<PublishersNote pageData={pg}/>} />
    if(typeName === 'ed-note') return <Route key={i} path={linkName} element={<EditorsNote pageData={pg}/>} />
    if(typeName === 'foreword') return <Route key={i} path={linkName} element={<AuthorsForeword pageData={pg}/>} />
    if(typeName === 'front-page') return <Route key={i} path={linkName} element={<Epigraph pageData={pg}/>} />

    const isNewSectionTitle = (typeName === 'intro-title-page') || (typeName === 'q-title-page') || (typeName === 'conclusion-title-page')
    if(isNewSectionTitle) return <Route key={i} path={linkName} element={<TitlePageSection pageData={pg}/>} />

    if(typeName === 'q') return <Route key={i} path={linkName} element={<Chapter pageData={pg}/>} />

    if(typeName === 'notes') return <Route key={i} path={linkName} element={<Notes pageData={pg}/>}/>
    if(typeName === 'bio') return <Route key={i} path={linkName} element={<Bio pageData={pg}/>}/>
    if(typeName === 'speech') return <Route key={i} path={linkName} element={<Speech pageData={pg}/>}/>

    return <Route key={i} path={linkName} element={<Page pageData={pg}/>} />
  })
}

function Page({pageData}) {
  const { data } = pageData
  return (
    <div>{data.title}</div>
  )
}

export default getBookRoutes