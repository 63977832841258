import PageNav from "../comps/PageNav"
import addLineBreaks from "../funcs/addLineBreaks"

export default function Epigraph({pageData}) {
  const { data, links } = pageData
  const { quote, author } = data
  return (
    <div>
      <PageNav links={links}/>
      <div className="container d-flex flex-column d-flex justify-content-center align-items-center min-vh-75">
        <div>
          <p>{addLineBreaks(quote)}</p>
          <p className="text-end">— {author}</p>
        </div>
      </div>
    </div>
  )
}