import PageNav from "../comps/PageNav"
import '../css/CopyrightInfo.css'
import addLineBreaks from "../funcs/addLineBreaks"

export default function CopyrightInfo({pageData}) {
  const { data, links } = pageData
  const { note, coverArt } = data
  return (
    <div>
      <PageNav links={links}/>
      <div className="container d-flex flex-column d-flex justify-content-around min-vh-75">
        <p className="text-center text-small">{addLineBreaks(note)}</p>
        <p className="text-center text-small">Cover Art:<br/>{coverArt}</p>
      </div>
    </div>
  )
}
