import PageNav from "../comps/PageNav"

export default function Speech({pageData}) {
  const { data, links } = pageData
  const { title, info, text } = data
  const paragraphs = text.map((t, i) => <p key={i}>{t}</p>)
 
  return (
    <div>
      <PageNav links={links}/>
      <div className="container min-vh-75">
        <h2 className="text-center my-5">{title}</h2>
        <p className="fst-italic">{info}</p>
        {paragraphs}
      </div>
    </div>
  )
}